import React from "react";
import ArrowIcon from "../arrow-icon";
import classes from "./delivery-info-section.module.scss";
import BracesIcon from "../braces-icon";
import classNames from "classnames";
import { feedback } from "../../constants/homepage";
import dummyPhoto from "../../image/dummy-profile-pic.png";

const DeliveryInfoSection = ({ braces = "right", border = false }) => {

  return (
    <div className={classNames(
        classes["deliverySection"],
        {
          [`${classes["bracesLeft"]}`]: braces === "left",
          [`${classes["border"]}`]: border,
        }
    )}>
      <div className={classes["text"]}>
        “{feedback.message}”
      </div>
      <div className={classes["deliveryAddress"]}>
        <span className={classes["shipmentLabel"]}>Zásielka:</span>
        <span className={classes["from"]}>{feedback.from}</span>
        <span className={classes["arrow"]}>
          <ArrowIcon />
        </span>
        <span className={classes["to"]}>{feedback.to}</span>
      </div>
      <div className={classes["user"]}>
        <div className={classes["logo"]}>
          <img src={dummyPhoto} alt={"Shipment logo"} />
        </div>
        <div className={classes["name"]}>{feedback.client.name}</div>
      </div>
      <div className={classes["braces"]}>
        <BracesIcon />
      </div>
    </div>
  );
};

export default DeliveryInfoSection;
