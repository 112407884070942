import React from "react";

function BracesIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="58"
            height="38"
            fill="none"
            viewBox="0 0 58 38"
        >
            <path
                fill="#F5F3EF"
                d="M7.65 37.5c-2.4 0-4.3-.5-5.7-1.5C.65 35 0 33.5 0 31.5c0-.7.05-1.45.15-2.25.1-.8.25-1.6.45-2.4.4-1.9 1.05-4 1.95-6.3.9-2.4 2.05-4.8 3.45-7.2 1.5-2.4 3.2-4.75 5.1-7.05 2-2.4 4.3-4.5 6.9-6.3h9.75c-3.7 3.2-6.7 6.35-9 9.45-2.2 3-4.1 6.2-5.7 9.6 3.3.8 4.95 2.65 4.95 5.55 0 .7-.05 1.45-.15 2.25-.1.8-.25 1.6-.45 2.4-.2 1-.5 2-.9 3s-1 1.9-1.8 2.7c-.7.7-1.65 1.3-2.85 1.8-1.1.5-2.5.75-4.2.75zm29.55 0c-2.4 0-4.3-.5-5.7-1.5-1.3-1-1.95-2.5-1.95-4.5 0-.7.05-1.45.15-2.25.1-.8.25-1.6.45-2.4.4-1.9 1.05-4 1.95-6.3.9-2.4 2.05-4.8 3.45-7.2 1.5-2.4 3.2-4.75 5.1-7.05 2-2.4 4.3-4.5 6.9-6.3h9.75c-3.7 3.2-6.7 6.35-9 9.45-2.2 3-4.1 6.2-5.7 9.6 3.3.8 4.95 2.65 4.95 5.55 0 .7-.05 1.45-.15 2.25-.1.8-.25 1.6-.45 2.4-.2 1-.5 2-.9 3s-1 1.9-1.8 2.7c-.7.7-1.65 1.3-2.85 1.8-1.1.5-2.5.75-4.2.75z"
            ></path>
        </svg>
    );
}

export default BracesIcon;
