import React, { useEffect, useState } from "react";
import classes from "./dropdown.module.scss";
import { getPlace } from "src/helpers/additionalFuctions";
import { DESTINATIONS } from "../../../constants/homepage";
import classNames from "classnames";

const AddressDropdown = ({
  manualAddMode,
  items,
  children,
  onClickManuallyAdd = () => {},
  onChange = () => {},
  isOpenDrop,
  setIsOpenDropDown,
  selectedItem,
  selectedId,
  ...props
}) => {
  const [isSelectedItem, setIsSelectedItem] = useState(false);
  const selectedAdress = async (item) => {
    if (item && item.placeId) {
      let { description, placeId } = item;
      const geoCodedaddress = await getPlace(placeId);

      onChange(description, geoCodedaddress, placeId);
    } else {
      onChange(item.description, item.geoCode);
    }
  };

  return (
    <>
      <div className={classes["wrapper"]}>
        <div className={classes["toggleSection"]}>{children}</div>
        {isOpenDrop ? (
          <ul className={classes["selectionList"]}>
            {items.map((item, index) => {
              return (
                <li
                  className={classNames({
                    [`${classes.selectedItem}`]: selectedId === index,
                  })}
                  key={index}
                  onMouseDown={() => {
                    selectedAdress(item);
                  }}
                >
                  {item.description}
                </li>
              );
            })}
            {manualAddMode && (
              <li className={classes["setManually"]}>
                Nenašlo vám správnu adresu? Zadajte
                <u>
                  <a role="presentation" onMouseDown={onClickManuallyAdd}>
                    ju ručne
                  </a>
                </u>
              </li>
            )}
          </ul>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default AddressDropdown;
