import React, { useEffect, useRef, useState } from "react";
import Autocomplete from "./autocomplete";
import AddressDropdown from "./address-dropdown";
import { toDropdownList, getPlace } from "src/helpers/additionalFuctions";
import InputText from "../input-text";
import { usePrevious } from "../../hooks/usePrevious";
import { TYPES_FORM, IOS_CODE, DESTINATIONS } from "../../constants/homepage";
import { allPlaceForSearchingForPickup } from "../../constants/homepage";

const SearchAddress = ({
  label = "",
  onChange,
  manualAddMode = true,
  onClickManuallyAdd,
  shadow = true,
  border = false,
  lastAddress = null,
  clearError,
  typeForm,
  address,
  isOpenDrop,
  setIsOpenDropDown,
  isCompanyInfo,
  name,
}) => {
  const [Locality, setLocality] = useState({});
  const [isOnlyCity, setIsOnlyCity] = useState(false);
  const prevTypeForm = usePrevious(typeForm ? typeForm : "");
  const IndexesRef = useRef(null);
  const [selectedId, setSelectedId] = useState();

  const adjustOptionForAutocomplite = () => {
    if (lastAddress && typeof setIsOpenDropDown === "function") {
      setIsOpenDropDown(true);
    }
    if (typeForm === TYPES_FORM.KE_CZ) {
      if (name === "pickUp") {
        setIsOnlyCity(true);
        setLocality({ locality: allPlaceForSearchingForPickup });
      } else {
        setIsOnlyCity(false);
        setLocality({ locality: [DESTINATIONS.country.CzechRepublic] });
      }
    } else if (typeForm === TYPES_FORM.KE_KE) {
      setIsOnlyCity(true);
      setLocality({ locality: allPlaceForSearchingForPickup });
    }
  };

  const AdjustValueForm = (address) => {
    if (typeof address === "string") {
      return address;
    } else {
      return;
    }
  };

  useEffect(() => {
    if (typeForm && prevTypeForm != typeForm && !isCompanyInfo) {
      onChange();
      clearError(false);
    }
  }, [prevTypeForm, typeForm, isCompanyInfo]);

  const handleKeyDown = async (event, suggestions) => {
    const maxIndexes = suggestions.length - 1;
    if (event.key === "Escape") {
      setIsOpenDropDown(false);
      IndexesRef.current = 0;
      setSelectedId(0);
      onChange("");
      return;
    }
    if (event.key === "ArrowDown") {
      IndexesRef.current =
        IndexesRef.current === maxIndexes || IndexesRef.current === null
          ? 0
          : IndexesRef.current + 1;
      setSelectedId(IndexesRef.current);
      const suggestion = suggestions[IndexesRef.current];
      onChange(suggestion.description);
      event.preventDefault();
    }

    if (event.key === "ArrowUp") {
      IndexesRef.current =
        IndexesRef.current === 0 || IndexesRef.current === null
          ? maxIndexes
          : IndexesRef.current - 1;

      const suggestion = suggestions[IndexesRef.current];
      onChange(suggestion.description);
      setSelectedId(IndexesRef.current);
    }

    if (event.key === "Enter") {
      if (
        IndexesRef.current !== null &&
        suggestions[IndexesRef.current].placeId
      ) {
        const { description, placeId } = suggestions[IndexesRef.current];
        const geoCodedAddress = await getPlace(placeId);
        onChange(description, geoCodedAddress, placeId);
      } else if (IndexesRef.current !== null) {
        onChange(
          suggestions[IndexesRef.current].description,
          suggestions[IndexesRef.current].geoCode
        );
      }
    }
  };

  return (
    <Autocomplete
      isOnlyCity={isOnlyCity}
      address={address ? address : ""}
      handleChange={onChange}
      initialize={({ getInputProps, suggestions }) => {
        const inputProps = getInputProps();
        let items = toDropdownList(suggestions, Locality);
        if (lastAddress) {
          items = [lastAddress, ...items];
        }

        return (
          <AddressDropdown
            isOpenDrop={isOpenDrop}
            onChange={onChange}
            onClickManuallyAdd={onClickManuallyAdd}
            items={items}
            manualAddMode={manualAddMode}
            lastAddress={lastAddress}
            setIsOpenDropDown={setIsOpenDropDown}
            selectedId={selectedId}
          >
            <InputText
              onFocus={() => adjustOptionForAutocomplite(suggestions)}
              border={border}
              shadow={shadow}
              {...inputProps}
              value={address ? AdjustValueForm(address) : ""}
              onBlur={(e) => {
                setIsOpenDropDown(false);
              }}
              onKeyDown={(event) => handleKeyDown(event, items)}
            />
          </AddressDropdown>
        );
      }}
    />
  );
};

export default SearchAddress;
