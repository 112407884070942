import React from "react";
import inputTextClasses from './input-text.module.scss';
import classNames from "classnames";

const InputText = function ({ children, shadow = false, border = false, ...props }) {
    return (
        <input className={classNames(
            inputTextClasses['input'],
            {
                [`${inputTextClasses["border"]}`]: border,
                [`${inputTextClasses["shadow"]}`]: shadow,
            }
        )} type="text" {...props} />
    );
};

export default InputText;
