import PlacesAutocomplete, {geocodeByAddress} from "react-places-autocomplete";
import React from "react";
import {IOS_CODE,DESTINATIONS} from "../../../constants/homepage"



const Autocomplete =   ({ address, handleChange, handleSelect,  isOnlyCity, initialize = () => {}}) => {
let searchOptions = {}


if(isOnlyCity){
     searchOptions = {
        componentRestrictions:{
            country: IOS_CODE.Slovakia
        },
        location: new google.maps.LatLng(DESTINATIONS.city.lat, DESTINATIONS.city.lat),
        radius: DESTINATIONS.city.radiusSearching,
        strictBounds: true,
        types: [`${DESTINATIONS.city.types}`]
    }
}else{
    searchOptions = {
        componentRestrictions:{
            country: IOS_CODE.CzechRepublic
        },
        types: [`${DESTINATIONS.city.types}`]
    }
}
     return (
        <PlacesAutocomplete
            searchOptions={searchOptions}
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
        >
            {(props) => initialize(props)}
        </PlacesAutocomplete>
    )
};

export default Autocomplete;